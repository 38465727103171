<template>
  <header class="p-3 bg-primary text-white">
    <div class="d-flex align-items-center">
      <svg width="40" height="40"><use xlink:href="#settings"></use></svg>
      <div class="lh-1 mx-2">
        <h1 class="h6 mb-0 text-white lh-1 fw-bold">設定</h1>
      </div>
      <div class="control-group">
        <button
          class="btn btn-warning btn-lg"
          @click.prevent="update"
        >更新</button>
      </div>
    </div>
  </header>

  <main class="p-3">

    <!--施設名称-->
    <div class="input-group input-group-lg mb-3">
      <span class="input-group-text" style="width: 220px;">施設名称</span>
      <input
        type="text"
        class="form-control"
        :class="{'is-invalid': !states.companyName}"
        v-model="breederInfo.companyName"
        placeholder="施設名称"
      >
      <div class="invalid-feedback">施設名称を入力してください</div>
    </div>

    <!--代表者名-->
    <div class="input-group input-group-lg mb-3">
      <span class="input-group-text" style="width: 220px;">代表者名</span>
      <input
        type="text"
        class="form-control"
        :class="{'is-invalid': !states.representative}"
        v-model="breederInfo.representative"
        placeholder="代表者名"
      >
      <div class="invalid-feedback">代表者名を入力してください</div>
    </div>

    <!--所在地-->
    <div class="input-group input-group-lg mb-3">
      <span class="input-group-text" style="width: 220px;">所在地</span>
      <input
        type="text"
        class="form-control"
        :class="{'is-invalid': !states.addr}"
        v-model="breederInfo.addr"
        placeholder="所在地"
      >
      <div class="invalid-feedback">所在地を入力してください</div>
    </div>

    <!--代表電話番号-->
    <div class="input-group input-group-lg mb-3">
      <span class="input-group-text" style="width: 220px;">代表電話番号</span>
      <input
        type="text"
        class="form-control"
        :class="{'is-invalid': !states.tel}"
        v-model="breederInfo.tel"
        placeholder="代表電話番号"
      >
      <div class="invalid-feedback">代表電話番号を入力してください</div>
    </div>

    <!--携帯電話番号-->
    <div class="input-group input-group-lg mb-3">
      <span class="input-group-text" style="width: 220px;">携帯電話番号</span>
      <input
        type="text"
        class="form-control"
        :class="{'is-invalid': !states.keitai}"
        v-model="breederInfo.keitai"
        placeholder="携帯電話番号"
      >
      <div class="invalid-feedback">携帯電話番号を入力してください</div>
    </div>

    <!--動物取扱業登録番号-->
    <div class="input-group input-group-lg mb-3">
      <span class="input-group-text" style="width: 220px;">動物取扱業登録番号</span>
      <input
        type="text"
        class="form-control"
        :class="{'is-invalid': !states.certifiedNum}"
        v-model="breederInfo.certifiedNum"
        placeholder="動物取扱業登録番号"
      >
      <div class="invalid-feedback">動物取扱業登録番号を入力してください</div>
    </div>

  </main>
</template>

<script>
import axios from "axios"
export default {
  data() {
    return {
      breederInfo: {
        companyName: "",
        representative: "",
        addr: "",
        tel: "",
        keitai: "",
        certifiedNum: "",
      },
      states: {
        companyName: true,
        representative: true,
        addr: true,
        tel: true,
        keitai: true,
        certifiedNum: true,
      }
    }
  },
  mounted() {
    axios
      .get("/wp-json/pasti/v1/breeder/self")
      .then( response => {
        console.log(response.data)
        this.breederInfo.companyName = response.data.company_name
        this.breederInfo.representative = response.data.representative
        this.breederInfo.addr = response.data.addr
        this.breederInfo.tel = response.data.tel
        this.breederInfo.keitai = response.data.keitai
        this.breederInfo.certifiedNum = response.data.certifiednum
      })
      .catch( error => {
        console.log(error);
      })
  },
  methods: {
    validate() {
      this.states.companyName = this.breederInfo.companyName ? true : false
      this.states.representative = this.breederInfo.representative ? true : false
      this.states.addr = this.breederInfo.addr ? true : false
      this.states.tel = this.breederInfo.tel ? true : false
      this.states.keitai = this.breederInfo.keitai ? true : false
      this.states.certifiedNum = this.breederInfo.certifiedNum ? true : false
      let res = true
      let states = {...this.states}
      Object.keys(states).forEach(function(key) {
        res *= states[key]
      })
      return res
    },
    update() {
      if(!this.validate())
        return

      axios
        .post("/wp-json/pasti/v1/breeder/self", this.breederInfo)
        .then( response => {
          console.log(response.data)
          alert("更新しました！")
        })
        .catch( error => {
          console.log(error);
        })
    }
  }
}
</script>